import { createStyles } from '@mantine/core';

type FloatingButtonProps = {
  position?: 'fixed' | 'static';
};

const FloatingButton = createStyles((theme, { position = 'fixed' }: FloatingButtonProps) => ({
  root: {
    width: '3rem',
    height: '3rem',
    position,
    right: '1.5rem',
    bottom: '1.5rem',
    background: theme.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '9999px',
    boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.15)',
  },
}));

export default FloatingButton;
