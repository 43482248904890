import React from 'react';
import foursixty from '../../../../axios/foursixty/foursixty';
import { Box, Grid, Group, Stack } from '../../../core';
import { SocialCard, Text } from '../../../shared';
import { responsiveStyles } from '../../../styles';
import Link from 'next/link';
import env from '@ui/env';

const HomeInstagramSection = (): React.ReactElement => {
  const responsive = responsiveStyles();

  const ref = React.useRef<HTMLDivElement | null>(null);

  const [posts, setPosts] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    if (ref && ref.current) {
      const script = document.createElement('script');

      script.src = '//foursixty.com/media/scripts/fs.slider.v2.5.js';
      script.setAttribute('data-feed-id', 'hellomolly');
      script.setAttribute('data-theme', 'slider_v2_5');
      script.setAttribute('data-open-links-in-same-page', 'true');
      script.setAttribute('data-connector-filter', '8381,44910');
      script.setAttribute('data-cell-size', '20%');
      ref.current.appendChild(script);
    }
  }, []);

  React.useEffect(() => {
    if (env.HOME_INSTAGRAM_SECTION) {
      foursixty().then((result) => {
        const feed: Array<{}> = [];

        result.data.results.forEach(
          (
            post: {
              [key: string]: string | undefined;
            },
            i: number,
          ) => {
            feed.push({
              id: post.id,
              src: post.main_image_url,
              height: i === 0 ? 488 : 208,
              width: i === 0 ? 508 : 228,
              description: post.title,
            });
          },
        );

        setPosts(feed);
      });
    }
  }, []);

  return (
    <Box className="!pb-0">
      <Stack spacing="2rem">
        <Group ref={ref} sx={{ '> *': { zIndex: 1 } }}>
          <Link href="https://www.instagram.com/hellomolly/?hl=en" target="_blank">
            <Text size="1rem" fw={700} lts="0.055em">
              @HELLOMOLLY
            </Text>
          </Link>
          <Link href="https://www.instagram.com/explore/tags/hellomolly/?hl=en" target="_blank">
            <Text size="1rem" c="dimmed" lts="0.055em">
              #HELLOMOLLY
            </Text>
          </Link>
        </Group>
        {posts.length > 0 && env.HOME_INSTAGRAM_SECTION && (
          <Box sx={{ overflow: 'hidden' }} className={responsive.classes.hiddenMobile}>
            <Grid gutter="lg">
              <Grid.Col span={5}>
                <SocialCard
                  src={posts[0].src}
                  alt=""
                  height={posts[0].height}
                  width={posts[0].width}
                  description={posts[0].description}
                />
              </Grid.Col>
              <Grid.Col span={7}>
                <Stack spacing="2rem" h="100%">
                  <Grid gutter="lg" sx={{ flex: '1 1 0%' }}>
                    {posts.slice(1, 4).map((post) => (
                      <Grid.Col key={post.id} span={4}>
                        <SocialCard
                          src={post.src}
                          alt=""
                          height={post.height}
                          width={post.width}
                          description={post.description}
                        />
                      </Grid.Col>
                    ))}
                  </Grid>
                  <Grid gutter="lg" sx={{ flex: '1 1 0%' }}>
                    {posts.slice(4, 7).map((post) => (
                      <Grid.Col key={post.id} span={4}>
                        <SocialCard
                          src={post.src}
                          alt=""
                          height={post.height}
                          width={post.width}
                          description={post.description}
                        />
                      </Grid.Col>
                    ))}
                  </Grid>
                </Stack>
              </Grid.Col>
            </Grid>
          </Box>
        )}
        {posts.length > 0 && env.HOME_INSTAGRAM_SECTION && (
          <Box sx={{ overflow: 'hidden' }} className={responsive.classes.hiddenDesktop}>
            <Grid gutter="xs">
              <Grid.Col span={6}>
                <SocialCard src={posts[0].src} alt="" height={136} width={136} />
              </Grid.Col>
              <Grid.Col span={6}>
                <Stack h="100%" spacing="xs">
                  <Grid gutter="xs" sx={{ flex: '1 1 0%' }}>
                    {posts.slice(1, 3).map((post) => (
                      <Grid.Col key={post.id} span={6}>
                        <SocialCard src={post.src} alt="" height={136} width={136} description="" />
                      </Grid.Col>
                    ))}
                  </Grid>
                  <Grid gutter="xs" sx={{ flex: '1 1 0%' }}>
                    {posts.slice(3, 5).map((post) => (
                      <Grid.Col key={post.id} span={6}>
                        <SocialCard src={post.src} alt="" height={136} width={136} description="" />
                      </Grid.Col>
                    ))}
                  </Grid>
                </Stack>
              </Grid.Col>
            </Grid>
            <Text size="0.75rem" fw={300} mt="0.5rem">
              {posts[0].description}
            </Text>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default HomeInstagramSection;
