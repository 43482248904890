import React from 'react';
import { NextImage } from '../../../core';
import { imageStyles } from '../../../styles';

type ZoomImageProps = {
  src: string;
  alt: string;
  height?: number | `${number}`;
  width?: number | `${number}`;
  style?: React.CSSProperties;
};

const ZoomImage = ({ src, alt, height, width, ...props }: ZoomImageProps): React.ReactElement => {
  const image = imageStyles();

  return <NextImage src={src} alt={alt} height={height} width={width} className={image.classes.zoomImage} {...props} />;
};

ZoomImage.defaultProps = {
  height: undefined,
  width: undefined,
  style: undefined,
};

export default ZoomImage;
