import React from 'react';
import { NextLink } from '../../../core';
import { ShopifyImageLoader } from '@ui/utils/ShopifyImageLoader';
import Image from 'next/image';
import { type Image as StaticImage } from '@ui/providers/static-content-provider';

export type HomeHeroSectionProps = {
  url: string;
  desktopImage: StaticImage;
  mobileImage: StaticImage;
};

const HomeHeroSection: React.FC<HomeHeroSectionProps> = ({ url, desktopImage, mobileImage }) => {
  return (
    <NextLink href={url} className="relative block w-full">
      <div className="hidden md:flex transition-all">
        <Image
          fetchPriority="high"
          loading="eager"
          loader={ShopifyImageLoader}
          alt={desktopImage.alt}
          height={desktopImage.height}
          width={desktopImage.width}
          quality={100}
          src={desktopImage.src}
          className="w-full h-auto object-cover"
          priority
        />
      </div>
      <div className="flex md:hidden transition-all">
        <Image
          fetchPriority="high"
          loading="eager"
          loader={ShopifyImageLoader}
          alt={mobileImage.alt}
          height={mobileImage.height}
          width={mobileImage.width}
          quality={100}
          src={mobileImage.src}
          className="w-full h-auto object-cover"
          priority
        />
      </div>
    </NextLink>
  );
};

export default HomeHeroSection;
