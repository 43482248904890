import React from 'react';
import { Portal, Transition, UnstyledButton, UnstyledButtonProps, UpArrowIcon } from '../../../core';
import { FloatingButton } from '../../../styles';
import cn from '@ui/utils/cn';

type ScrollToTopButtonProps = UnstyledButtonProps & {
  position?: 'fixed' | 'static';
};

const ScrollToTopButton = ({ position, className, ...props }: ScrollToTopButtonProps): React.ReactElement => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const onScroll = () => {
      const ceiling = window.innerHeight / 2;

      if (document.body.scrollTop > ceiling || document.documentElement.scrollTop > ceiling) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const button = FloatingButton({ position: position || 'fixed' });

  const render = (
    <Transition transition="pop" mounted={open}>
      {(styles) => (
        <UnstyledButton
          {...props}
          className={cn(button.classes.root, className)}
          style={styles}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          sx={{ zIndex: 1 }}
        >
          <UpArrowIcon height={26} width={18} />
        </UnstyledButton>
      )}
    </Transition>
  );

  return position === 'static' ? render : <Portal>{render}</Portal>;
};

ScrollToTopButton.defaultProps = {
  position: undefined,
};

export default ScrollToTopButton;
