import React from 'react';
import Marquee from 'react-fast-marquee';
import { AfterpayIcon, Container, Group, KlarnaIcon, UnidaysIcon } from '@ui/components/core';
import { Text } from '@ui/components/shared/typography';
import { BannerCurrencyDropdown, LocalCurrencyDropdown } from '@ui/components/features';
import env from '@ui/env';
import { StaticContent } from '@ui/providers';
import { useStaticContent } from '@ui/providers/static-content-provider';

type BannerProps = {
  hideCurrencyDropdown?: boolean;
};

const Banner = ({ hideCurrencyDropdown }: BannerProps) => {
  const paymentChannels = useStaticContent('WearNowPayLater.Channels');

  return (
    <div
      className="border-b-[0.6px] border-b-black bg-pink-3 flex items-center h-[--banner-height]"
      data-test="usp-banner"
    >
      <Container className="px-4 md:px-8">
        <div className="flex items-center space-x-4">
          {!hideCurrencyDropdown && !env.MULTICURRENCY_FEATURE && <BannerCurrencyDropdown />}
          {!hideCurrencyDropdown && env.MULTICURRENCY_FEATURE && <LocalCurrencyDropdown />}
          <div className="flex-1 overflow-hidden">
            <Marquee>
              <Group spacing="4rem" mr="4rem">
                {Array.from(Array(3).keys()).map((iteration) => (
                  <React.Fragment key={iteration}>
                    <Text sx={{ whiteSpace: 'nowrap' }}>
                      <StaticContent id="Banner.FreeShipping" inline />
                    </Text>
                    <Text sx={{ whiteSpace: 'nowrap' }}>
                      <b>100+ NEW ARRIVALS</b> EVERY WEEK!
                    </Text>
                    {env.UNIDAYS_FEATURE && (
                      <Group spacing="0.25rem" align="center" noWrap>
                        <Text sx={{ whiteSpace: 'nowrap' }}>STUDENTS GET 10% OFF WITH</Text>
                        <UnidaysIcon height={8.5} width={47.15} />
                      </Group>
                    )}
                    {(env.PRODUCT_BNPL_FEATURE || env.NEXT_PUBLIC_REGION === 'AU') && (
                      <Group spacing="0.25rem" align="center" noWrap>
                        <Text sx={{ whiteSpace: 'nowrap' }}>WEAR NOW. PAY LATER</Text>
                        {paymentChannels.includes('afterpay') && <AfterpayIcon height={10.5} width={51} />}
                        {paymentChannels.includes('klarna') && <KlarnaIcon height={9} width={41.53} />}
                      </Group>
                    )}
                  </React.Fragment>
                ))}
              </Group>
            </Marquee>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Banner;
