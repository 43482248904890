import React from 'react';
import { Card as MantineCard, CardProps as MantineCardProps, CardSectionProps } from '@mantine/core';

type CardProps = MantineCardProps & {
  section?: React.ReactElement;
  sectionProps?: CardSectionProps;
};

const Card = ({ section, sectionProps, children, ...props }: CardProps) => (
  <MantineCard {...props}>
    {section && <MantineCard.Section {...sectionProps}>{section}</MantineCard.Section>}
    {children}
  </MantineCard>
);

Card.defaultProps = {
  section: undefined,
  sectionProps: undefined,
};

export default Card;
