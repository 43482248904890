import axios from 'axios';

const foursixty = async () => {
  const { data } = await axios.request({
    method: 'GET',
    url: 'https://foursixty.com/api/v2/hellomolly/timeline/?pagination_type=cursor&page_size=7&format=json&page=1&use_stored_image_url=true',
    headers: {
      accept: 'application/json',
    },
  });

  return { data };
};

export default foursixty;
