import React from 'react';
import { Box, Grid } from '../../../core';
import { LinkFeatureCard } from '../../../shared';
import { useStaticContent } from '@ui/providers/static-content-provider';

type HomeCategorySectionProps = {
  md?: number;
  span?: number;
};

const HomeCategorySection = ({ md = 3, span = 6 }: HomeCategorySectionProps): React.ReactElement => {
  const images = useStaticContent('Home.FeaturedCategories');

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Grid gutter="sm" gutterMd="lg">
        {images.map(({ alt, height, href, label, src, width }) => (
          <Grid.Col className="hover:cursor-pointer" key={alt} span={span} md={md}>
            <LinkFeatureCard src={src} alt={alt} label={label ?? ''} href={href ?? ''} height={height} width={width} />
          </Grid.Col>
        ))}
      </Grid>
    </Box>
  );
};

export default HomeCategorySection;
