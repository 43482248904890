import { createStyles } from '@mantine/core';

const imageStyles = createStyles(() => ({
  zoomImage: {
    transition: 'transform .5s',
    ':hover': {
      transform: 'scale(1.2)',
    },
  },
}));

export default imageStyles;
