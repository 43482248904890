import React from 'react';
import { Card } from '../../../core';
import { Text } from '../../typography';
import ZoomImage from '../../images/ZoomImage/ZoomImage';

type SocialCardProps = {
  src: string;
  alt: string;
  height: number;
  width: number;
  description?: string;
};

const SocialCard = ({ src, alt, height, width, description }: SocialCardProps) => (
  <Card
    padding={0}
    section={
      <ZoomImage
        src={src}
        alt={alt}
        height={height}
        width={width}
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
          aspectRatio: `${width} / ${height}`,
        }}
      />
    }
    sectionProps={{ sx: { overflow: 'hidden' } }}
  >
    {description && (
      <Text size="0.75rem" fw={300} mt="0.5rem" lineClamp={2}>
        {description}
      </Text>
    )}
  </Card>
);

SocialCard.defaultProps = {
  description: undefined,
};

export default SocialCard;
