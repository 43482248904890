import React from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { Box, Card, Center, NextImage, NextLink, Text } from '../../../core';
import { ShopifyImageLoader } from '../../../../utils/ShopifyImageLoader';

type LinkFeatureCardProps = {
  src: string;
  alt: string;
  label: string;
  href: string;
  height: number;
  width: number;
  onClick?: () => void;
  onDoubleClick?: () => void;
};

const LinkFeatureCard = ({
  src,
  alt,
  label,
  href,
  height,
  width,
  onClick,
  onDoubleClick,
}: LinkFeatureCardProps): React.ReactElement => {
  const isMobile = useMediaQuery('(max-width: 30rem)');
  const [active, setActive] = React.useState(false);

  return (
    <NextLink href={href}>
      <Card padding={0}>
        <>
          <NextImage
            onClick={
              active
                ? () => {
                    onClick?.();
                    setActive(true);
                  }
                : () => {
                    onDoubleClick?.();
                    setActive(false);
                  }
            }
            loader={ShopifyImageLoader}
            src={src}
            alt={alt}
            quality={100}
            height={height}
            width={width}
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
          {label && (
            <Center h={54}>
              <Box sx={{ borderBottom: '0.0625rem solid black' }}>
                <Text size={isMobile ? '0.75rem' : '1rem'} fw={700} tt="uppercase">
                  {label}
                </Text>
              </Box>
            </Center>
          )}
        </>
      </Card>
    </NextLink>
  );
};

export default LinkFeatureCard;
