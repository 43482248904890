import React from 'react';

const AfterpayIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 68 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.59056 6.75629C5.59056 5.51117 4.73155 4.63498 3.6754 4.63498C2.61925 4.63498 1.76025 5.52654 1.76025 6.75629C1.76025 7.97066 2.61925 8.8776 3.6754 8.8776C4.73155 8.8776 5.59056 8.0014 5.59056 6.75629ZM5.60464 10.4609V9.49247C5.08361 10.1535 4.30909 10.5685 3.37968 10.5685C1.45045 10.5685 0 8.93908 0 6.75629C0 4.57349 1.52086 2.92871 3.42193 2.92871C4.32318 2.92871 5.08361 3.34375 5.60464 3.98936V3.05168H7.32264V10.4609H5.60464Z"
      fill="black"
    />
    <path
      d="M15.7167 8.81629C15.1112 8.81629 14.9422 8.58571 14.9422 7.95547V4.68128H16.0547V3.06724H14.9422V1.26874H13.1679V3.06724H10.8866V2.62145C10.8866 2.00658 11.1119 1.76063 11.7315 1.76063H12.1258V0.331055H11.2809C9.81638 0.331055 9.12637 0.838325 9.12637 2.3755V3.08261H8.14062V4.68128H9.12637V10.4764H10.9007V4.68128H13.182V8.30902C13.182 9.81546 13.7312 10.4764 15.1675 10.4764H16.0829V8.83166H15.7167V8.81629Z"
      fill="black"
    />
    <path
      d="M22.066 6.09556C21.9393 5.12713 21.1929 4.55838 20.3198 4.55838C19.4468 4.55838 18.7286 5.12713 18.5455 6.09556H22.066ZM18.5314 7.24844C18.6582 8.33984 19.4045 8.97008 20.348 8.97008C21.0943 8.97008 21.6717 8.60116 22.0097 8.00166H23.8263C23.4038 9.56958 22.066 10.5841 20.3198 10.5841C18.1935 10.5841 16.7148 9.0162 16.7148 6.78729C16.7148 4.55838 18.292 2.94434 20.3621 2.94434C22.4603 2.94434 23.9671 4.57375 23.9671 6.78729C23.9671 6.95638 23.953 7.1101 23.9248 7.26381H18.5314V7.24844Z"
      fill="black"
    />
    <path
      d="M35.203 6.75654C35.203 5.55754 34.344 4.63523 33.2879 4.63523C32.2317 4.63523 31.3727 5.5268 31.3727 6.75654C31.3727 7.97092 32.2317 8.87785 33.2879 8.87785C34.3581 8.87785 35.203 7.95554 35.203 6.75654ZM29.6406 3.06731H31.3727V4.03573C31.8937 3.35938 32.6683 2.94434 33.5977 2.94434C35.4987 2.94434 36.9774 4.58912 36.9774 6.75654C36.9774 8.93934 35.4565 10.5841 33.5554 10.5841C32.6683 10.5841 31.936 10.2152 31.429 9.58495V13.5048H29.6406V3.06731Z"
      fill="black"
    />
    <path
      d="M43.2038 6.75629C43.2038 5.51117 42.3448 4.63498 41.2887 4.63498C40.2325 4.63498 39.3735 5.52654 39.3735 6.75629C39.3735 7.97066 40.2325 8.8776 41.2887 8.8776C42.3448 8.8776 43.2038 8.0014 43.2038 6.75629ZM43.2179 10.4609V9.49247C42.6969 10.1535 41.9224 10.5685 40.993 10.5685C39.0637 10.5685 37.6133 8.93908 37.6133 6.75629C37.6133 4.57349 39.1341 2.92871 41.0352 2.92871C41.9365 2.92871 42.6969 3.34375 43.2179 3.98936V3.05168H44.95V10.4609H43.2179Z"
      fill="black"
    />
    <path
      d="M26.5305 3.78953C26.5305 3.78953 26.967 2.92871 28.0514 2.92871C28.5161 2.92871 28.8118 3.0978 28.8118 3.0978V4.98853C28.8118 4.98853 28.164 4.55812 27.5726 4.65035C26.9811 4.74258 26.6009 5.31134 26.6009 6.07993V10.4609H24.8125V3.05168H26.5446V3.78953H26.5305Z"
      fill="black"
    />
    <path
      d="M53.004 3.06689L48.6385 13.489H46.7938L48.5118 9.46157L45.625 3.06689H47.7091L49.399 7.34026L51.1311 3.06689H53.004Z"
      fill="black"
    />
    <path
      d="M66.9173 2.89796L62.5941 0.269381C61.3268 -0.499209 59.7355 0.469215 59.7355 2.0064V2.28309C59.7355 2.52903 59.8622 2.75961 60.0594 2.86722L60.8761 3.35911C61.1155 3.51283 61.4112 3.32837 61.4112 3.03631V2.35995C61.4112 2.02177 61.7492 1.82193 62.0309 1.97565L65.7767 4.25068C66.0583 4.41977 66.0583 4.83481 65.7767 5.0039L62.0449 7.2943C61.7633 7.46339 61.4253 7.24818 61.4253 6.91V6.55645C61.4253 5.0039 59.8341 4.05084 58.5667 4.81943L54.2435 7.44801C52.9761 8.2166 52.9761 10.1535 54.2435 10.922L58.5667 13.5506C59.8341 14.3192 61.4253 13.3508 61.4253 11.8136V11.5369C61.4253 11.291 61.2986 11.0758 61.1014 10.9528L60.2847 10.4609C60.0453 10.3072 59.7496 10.4916 59.7496 10.7837V11.4601C59.7496 11.7982 59.4116 11.9981 59.13 11.8443L55.3841 9.56932C55.1025 9.40023 55.1025 8.98519 55.3841 8.8161L59.13 6.54108C59.4116 6.37199 59.7496 6.58719 59.7496 6.92537V7.27892C59.7496 8.83147 61.3408 9.78453 62.6082 9.01594L66.9314 6.38736C68.1988 5.6034 68.1988 3.66655 66.9173 2.89796Z"
      fill="black"
    />
  </svg>
);

export default AfterpayIcon;
