import { ProductCardCarousel } from '../../../shared';
import { useValidProducts } from '@ui/store/recentlyViewedStore';
import { CarouselProps } from '@mantine/carousel';
import cn from '@ui/utils/cn';

type HomeRecentlyViewedCarouselProps = {
  className?: string;
  alignTitleToCarousel?: boolean;
  classNames?: CarouselProps['classNames'];
};

const HomeRecentlyViewedSection = ({
  className,
  alignTitleToCarousel,
  classNames,
}: HomeRecentlyViewedCarouselProps) => {
  const products = useValidProducts();

  if (!products?.length) {
    return null;
  }

  return (
    <div className={className}>
      <div className="flex items-center justify-between mb-3 md:mb-6">
        <div className={cn('text-[14px] md:text-base font-bold tracking-[0.66px]', alignTitleToCarousel && 'pl-2')}>
          RECENTLY VIEWED
        </div>
      </div>
      <ProductCardCarousel
        layout="product"
        carousel={{ tag: '', placement: 'home-page', products }}
        classNames={{
          ...classNames,
          root: cn('-mx-3 md:mx-0', classNames?.root),
          viewport: cn('px-3 md:px-0', classNames?.viewport),
          slide: cn('w-[70%] md:w-[18%]', classNames?.slide),
        }}
        height={450}
        width={300}
        withControls
      />
    </div>
  );
};

export default HomeRecentlyViewedSection;
