import { ImageLoader } from 'next/image';

export const ShopifyImageLoader: ImageLoader = ({ src, width, quality }) => {
  const normalizedSrc = src.replace(/\?.*/g, '');

  if (quality !== undefined && quality < 0) {
    return `${normalizedSrc}`;
  }

  return `${normalizedSrc}?width=${width}&quality=${quality || 100}`;
};
